import * as Turbo from '@hotwired/turbo'
import ahoy from 'ahoy.js'
import CookieConsent from './_dist/cookie_consent.js'
Turbo.start()
document.addEventListener("turbo:load", onLoad);

function updateAnalytics() {
  ahoy.configure({
    visitsUrl: "/poll/visits",
    eventsUrl: "/poll/events",
    cookies: window.cc.acceptedCategories.indexOf('analytics') != -1,
    visitParams: {},
  });

  ahoy.trackView();
  ahoy.trackClicks("a, button, input[type=submit]");
  ahoy.trackSubmits("form");

  const domainGtag = document.getElementsByName("gtag")[0].content

  if (domainGtag) {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', domainGtag);
  }
}


function onLoad() {
  if (document.querySelector("[data-tabs]")) new Tabby('[data-tabs]');

  if (!window.testEnvironment){
      window.CookieConsent = null
      CookieConsent()
      window.cc = new window.CookieConsent({
        title: "Wir verwenden Cookies 🍪",
        description: `Wir verwenden Cookies. Viele sind notwendig, um die Website und ihre Funktionen zu betreiben, andere sind für statistische oder Marketingzwecke. Mit der Entscheidung "Ablehnen" werden wir Ihre Privatsphäre respektieren und keine Cookies setzen, die nicht für den Betrieb der Seite notwendig sind.`,
        buttons: {
          acceptAll: "Alle akzeptieren",
          acceptSelected: "akzeptieren",
          reject: "Ablehnen",
          showSettings: "Cookie Einstellungen",
          hideSettings: "Schließen",
        },
        categories: {
          essentials: {
            label: "Essentiell",
            description: `Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.`,
            checked: true,
            mandatory: true
          },
          analytics: {
            label: "Statistik",
            description: `Statistik-Cookies helfen Webseiten-Besitzern zu verstehen, wie Besucher mit Webseiten interagieren, indem Informationen anonym gesammelt und gemeldet werden.`,
            checked: true,
            mandatory: true
          }
        },
      })
    window.cc.on("change", (cc) => {
      updateAnalytics()
    })


    updateAnalytics()
  }

  document.querySelectorAll("input[name='order[payment_method]']").forEach((input) => {
    input.addEventListener('change', (event) => {
      if(event.target.id == "order_payment_method_creditcard"){
        document.querySelector('#iframe').style.display = "block"
      }else{
        document.querySelector('#iframe').style.display = "none"
      }
    });
    if (input.value == "creditcard" && input.checked){
      document.querySelector('#iframe').style.display = "block"
    }

  });

  const IFRAME_ORIGIN = 'https://cc.secupay.com';
  const iframeElement = document.getElementById('iframe');

  if (iframeElement){
    const iframeContentWindow = iframeElement.contentWindow;
    const form = document.getElementById("checkoutform")
     

    window.addEventListener('message', function (e) {
      // Check origin
      if (e.origin !== IFRAME_ORIGIN) {
        console.warn('bad origin ' + e.origin);
        return;
      }
     
      switch(e.data.action) {
         
        // Iframe height changed. This is raised on load, and after validation.
        case 'cc-iframe-height':
          iframeElement.height = e.data.documentHeight + 20 + 'px';
          break;
         
        // Iframe sends data. These may be valid or not.
        case 'prepared-cc-data':
          if (e.data.data.allFieldsAreValid){
            const payment_object = {
              owner: e.data.data.owner.value,
              pan: e.data.data.cardNumber.value,
              expiration_date: `${e.data.data.yearExp.value}-${e.data.data.monthExp.value}-01T00:00:00+00:00`,
              issuer: e.data.data.cardNumber.company,
              transact_container: e.data.data.encryptedData.crypted_container,
              transact_skey_pubkey: e.data.data.encryptedData.crypted_skey,
              transact_skey_keyname: e.data.data.encryptedData.key_filename,
              transact_hash: e.data.data.encryptedData.container_hash
            }

            document.querySelector('#order_payment_data').value = btoa(JSON.stringify(payment_object))
            form.submit()
          } else {
            document.querySelector('#order_payment_data').value = ""
          }
          break;
         
        default:
          break;
      }
    }, false);
    if  (form){
      form.addEventListener("submit", (event) => {
        iframeContentWindow.postMessage({ action: "get-cc-data" }, IFRAME_ORIGIN);
        if (document.querySelector('#iframe').style.display == "block" && document.querySelector('#order_payment_data').value.length === 0) event.preventDefault();
      });
    }
  }



  let menuToggle = document.querySelector('#menu-toggle');
  let menu = document.querySelector('#menu');

  if(menuToggle && menu) {
      let closeButton = menu.querySelector('button.is--toggle');
      let menuItems = menu.querySelectorAll('nav ul li a');

      menuToggle.addEventListener('click', () => {
          menu.classList.toggle('is--active');
      })

      if(closeButton) {
          closeButton.addEventListener('click', () => {
              menu.classList.toggle('is--active');
          })
      }

      if(menuItems) {
          menuItems.forEach(menuItem => {
              menuItem.addEventListener('click', () => {
                  menu.classList.toggle('is--active');
              })
          });
      }
  }

  let countdowns = document.querySelectorAll('.countdown');


  function setCountdown(countdown, countDownDate, x) {
    let now = new Date().getTime();
    let distance = countDownDate - now;

    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    countdown.innerHTML = days + "D " + hours + "H "
    + minutes + "M " + seconds + "S ";

    if (distance < 0) {
      if (x) clearInterval(x);
      countdown.innerHTML = "Abgelaufen";
    }
  }

  if(countdowns) {
    countdowns.forEach(countdown => {
      let countDownDate = new Date(countdown.dataset.date).getTime();
      setCountdown(countdown, countDownDate)
      let x = setInterval(function() {
        setCountdown(countdown, countDownDate, x)
      }, 1000);
    })
  }


  let tooltips = document.querySelectorAll('.tooltip');

  if(tooltips) {
      tooltips.forEach(tooltip => {
          let tooltipIcon = tooltip.querySelector('.btn');
          let tooltipWrapper = tooltip.querySelector('.tooltip-wrapper');

          if(tooltipIcon && tooltipWrapper) {
              let totoltipCloseBtn = tooltipWrapper.querySelector('.btn.is--toggle');
              
              tooltipIcon.addEventListener('click', () => {
                  tooltip.classList.toggle('is--active');
              })

              totoltipCloseBtn.addEventListener('click', () => {
                  tooltip.classList.toggle('is--active');
              })
          }
      });
  }

  let counters = document.querySelectorAll('.counter');

  if(counters) {
      counters.forEach(counter => {
          let counterInput = counter.querySelector('input');
          let counterButtonMinus = counter.querySelector('.btn.is--minus');
          let counterButtonPlus = counter.querySelector('.btn.is--plus');

          if(counterInput && counterButtonMinus && counterButtonPlus) {
              let counterInputStep = counterInput.step ? parseFloat(counterInput.step) : 1;
              let counterInputMin = counterInput.min ? parseFloat(counterInput.min) : 0;
              let counterInputMax = counterInput.max ? parseFloat(counterInput.max) : 100;

              counterButtonMinus.addEventListener('click', () => {
                  let counterInputValue = parseFloat(counterInput.value);

                  if(counterInputValue > counterInputMin) {
                      counterInput.value = parseFloat(counterInput.value) - counterInputStep;
                  }
              })

              counterButtonPlus.addEventListener('click', () => {
                  let counterInputValue = parseFloat(counterInput.value);
                  
                  if(counterInputValue < counterInputMax) {
                      counterInput.value = parseFloat(counterInput.value) + counterInputStep;
                  }
              })
          }
      });
  }

  // let formButtons = document.querySelectorAll('form button');

  // if(formButtons) {
  //     formButtons.forEach(formButton => {
  //         formButton.addEventListener('click', (e) => {
  //         })
  //     });
  // }

  let specialCodes = document.querySelectorAll('.special_code');
  specialCodes.forEach(input => {
    input.addEventListener('input', (e) => {
      let submitButton = input.parentNode.parentNode.querySelector('.ticket-submit')
      let disabledButton = input.parentNode.parentNode.querySelector('.is--disabled')

      const formData1 = new FormData(submitButton.parentNode)
      fetch("/codecheck.json", {
        body: formData1,
        method: "PATCH"
      }).then(res => res.json()).then(data =>{
        if(data.valid){
          disabledButton.classList.add("is--hidden")
          submitButton.classList.remove("is--hidden")
        }else{
          disabledButton.classList.remove("is--hidden")
          submitButton.classList.add("is--hidden")
        }

      })

    })
  })



  let inputWrappers = document.querySelectorAll('.input-wrapper');

  if(inputWrappers) {
      inputWrappers.forEach(inputWrapper => {
          let input = inputWrapper.querySelector('input, select, textarea');
          let label = inputWrapper.querySelector('label.is--floating');

          if(label && input) {
              if(input.value) {
                  label.classList.add('is--active');
              } else {
                  label.classList.remove('is--active');
              }

              input.addEventListener('focus', () => {
                  label.classList.add('is--active');
              })

              input.addEventListener('focusout', () => {
                  if(input.value) {
                      label.classList.add('is--active');
                  } else {
                      label.classList.remove('is--active');
                  }
              })
          }
      });
  }

  let ticketButtons = document.querySelectorAll('.ticket .ticket-submit');
  let animationDuration = 850;

  if(ticketButtons) {
      ticketButtons.forEach(ticketButton => {
          ticketButton.style.setProperty("--transition-duration", animationDuration + 'ms');

          ticketButton.addEventListener('click', (e) => {
              e.preventDefault();
              ticketButton.classList.add('animation--play');
              ahoy.track("Add", {product_id: ticketButton.dataset.product});
              const formData = new FormData(ticketButton.parentNode)
              fetch(ticketButton.parentNode.getAttribute("action")+".json", {
                body: formData,
                method: "PATCH"
              }).then(res => res.json()).then(data =>{
                if (data.redirect_to) window.location = data.redirect_to
                document.querySelector('.indicator').innerText = data.count
                document.querySelector('.indicator').style.display = "flex"
              });
              setTimeout(() => {
                  ticketButton.classList.remove('animation--play');
                  // ticketButton.parentNode.submit()
              }, animationDuration);
          })
      });
  }

  let advanceSalesStandSearch = document.querySelector('input#advance_sales_stand_search');
  let advanceSalesStandTable = document.querySelector('table#advance_sales_stands');

  if(advanceSalesStandSearch && advanceSalesStandTable) {
      let advanceSalesStandTableRows = advanceSalesStandTable.querySelectorAll('tbody tr');

      if(advanceSalesStandTableRows) {
          advanceSalesStandSearch.addEventListener('input', () => {
              let regex = new RegExp(advanceSalesStandSearch.value, 'gi');
              ahoy.track("advanceSalesStandSearch", {q: advanceSalesStandSearch.value});

              advanceSalesStandTableRows.forEach(advanceSalesStandTableRow => {
                  let counter = 0;
                  let advanceSalesStandTableRowCells = advanceSalesStandTableRow.querySelectorAll('th, td');

                  if(advanceSalesStandTableRowCells) {
                      advanceSalesStandTableRowCells.forEach(advanceSalesStandTableRowCell => {
                          if(advanceSalesStandTableRowCell.textContent.match(regex) && advanceSalesStandSearch.value != "") {
                              counter++;
                          }
                      });
                  }

                  if(counter > 0 || advanceSalesStandSearch.value == "") {
                      advanceSalesStandTableRow.classList.remove('is--hidden');
                  } else {
                      advanceSalesStandTableRow.classList.add('is--hidden');
                  }
              });
          })
      }
  }

  let verticalAccordionBlocks = document.querySelectorAll('.vertical-accordion-container');

  if(verticalAccordionBlocks) {
      verticalAccordionBlocks.forEach(block => {
          let verticalAccordions = block.querySelectorAll('.vertical-accordion');

          if(verticalAccordions) {
              verticalAccordions.forEach(accordion => {
                  let summary = accordion.querySelector('summary');

                  if(summary) {
                      summary.addEventListener('click', () => {
                          verticalAccordions.forEach(verticalAccordion => {
                              if(verticalAccordion != accordion) {
                                  verticalAccordion.open = false;
                              }
                          });
                      });
                  }
              });
          }
      });
  }
}

